import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Grid } from '@material-ui/core';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Badge from '@atoms/Badge/Badge';
import Link from '@components/Link';
import CardWithBackground from '../Card/CardWithBackground';
import './AmbassadorList.scss';

interface Props {
    list: any[];
}

const AmbassadorList: React.FC<Props> = ({ list }) => {
    const { t } = useTranslation();

    if (!list || (list && list.length === 0)) return <></>;

    return (
        <div className="AmbassadorList">
            <Section container="short">
                <Title align="center">
                    {t('ressourcen.ambassador.list.title')}
                </Title>

                <div className="g_1_4 g_center">
                    <Divider spacing="m" />
                </div>

                <div className="g_2_3 g_center">
                    <Text align="center">
                        {t('ressourcen.ambassador.list.desc')}
                    </Text>
                </div>
            </Section>
            <Section container="large" spacingTop="s">
                <Grid container spacing={4}>
                    {list.map((item) => (
                        <Grid item xs={12} sm={6} md={4} key={item.slug}>
                            <Link to={'/stories/' + item.slug}>
                                <CardWithBackground
                                    url={
                                        item.coverPicture?.data?.attributes
                                            ?.url || ''
                                    }
                                    urlSharp={
                                         item.coverPicture?.data?.attributes
                                            ?.urlSharp || ''
                                    }
                                    greenBackground
                                >
                                    {item.type === 'community_stories' && (
                                        <Badge
                                            variant="green"
                                            size="s"
                                            type="callout"
                                        >
                                            {t(
                                                'client.stories.community.stories'
                                            )}
                                        </Badge>
                                    )}

                                    {item.type ===
                                        'professional_perspectives' && (
                                        <Badge
                                            variant="new-2"
                                            size="s"
                                            type="callout"
                                        >
                                            {t(
                                                'client.stories.professional.perspectives'
                                            )}
                                        </Badge>
                                    )}

                                    <Title
                                        tag="span"
                                        size="l"
                                        font="alt"
                                        theme="white"
                                    >
                                        {item.title}
                                    </Title>
                                    <Text theme="white" size="s">
                                        {t('button.read')}
                                    </Text>
                                </CardWithBackground>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Section>
        </div>
    );
};

export default AmbassadorList;
