import React from 'react';

import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import Divider from '@atoms/Divider/Divider';

LightBanner.defaultProps = {
    title: 'My title',
    subtitle: 'My description',
};

export default function LightBanner({
    title,
    description,
    button,
    secondButton,
}) {
    return (
        <div className="LightBanner">
            <Section container="short" theme="white" spacing="m">
                <div className="g_center">
                    <Title size="l" align="center">
                        {title}
                    </Title>
                    <div className="g_1_2 g_center">
                        <Divider invisible spacing="xs" />
                    </div>
                    <Text align="center">{description}</Text>
                    <Divider invisible spacing="xs" />
                    <div className="LightBanner--buttons">
                        {button ? button : ''}
                        {secondButton ? secondButton : ''}
                    </div>
                </div>
            </Section>
        </div>
    );
}
