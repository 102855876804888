import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '@atoms/Title/Title';
import Section from '@atoms/Section/Section';

const LanguageNotSupported = () => {
    const { t } = useTranslation();
    return (
        <Section spacingTop="l">
            <Title tag="h1" align="center" font="alt" size="xxl">
                {t('resources.language.not.supported.title')}
            </Title>
        </Section>
    );
};

export default LanguageNotSupported;
