import React from 'react';

import './Badge.scss';

Badge.defaultProps = {
    size: 'm',
    variant: 'info',
    type: 'single', // callout
};

function Badge(props) {
    let mod_class = [
        'Badge',
        props.className ? props.className : '',
        props.size !== undefined ? 'size-' + props.size : '',
        props.variant !== undefined ? 'v-' + props.variant : '',
        props.type !== undefined ? 'type-' + props.type : '',
        props.align !== undefined ? 'align-' + props.align : '',
    ];
    mod_class = mod_class.join(' ');

    return <span className={mod_class}>{props.children}</span>;
}

Badge.propTypes = {};

export default Badge;
