import React from 'react';

import './CallToScrollDown.scss';

import { IoIosArrowRoundDown } from 'react-icons/io';

export default function CallToScrollDown(props) {
    let mod_class = [
        'CallToScrollDown',
        props.align === 'center' ? 'g_center' : '',
        props.theme !== undefined ? 'theme-' + props.theme : '',
    ];
    mod_class = mod_class.join(' ');
    return (
        <div className={mod_class}>
            <div className="CallToScrollDown--icon">
                <IoIosArrowRoundDown />
            </div>
        </div>
    );
}
